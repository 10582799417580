import React from 'react';
import { useDeleteUserEmailConfig } from './hook';
import ConfirmationDialog from '../../core-components/theme/components/DialogBox/ConfirmationDialog';


export default function DeleteUserEmailConfig({ userData, handleClose, open }: any) {
    const { actions = [] } = useDeleteUserEmailConfig(handleClose, userData);

    return (
        <React.Fragment>
            <ConfirmationDialog
                open={open}
                handleClose={handleClose}
                title='Delete Email Report Group'
                description="Are you sure you want to delete this Email Report Group?"
                actions={actions}
            />
        </React.Fragment>
    );
}
