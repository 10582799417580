import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
// import L from "leaflet"; // Assuming Leaflet is used

const renderMarkers = (map, markers = []) => {
  const markerBounds = [];
  const createdMarkers = markers?.map((m) => {
    if (m.position && Array.isArray(m.position)) {
      const { position, draggable, title, tooltip, icon: iconSrc, onClick, onDragend } = m;
      let fontWeight = m.fontWeight || "bold";
      let fontColor = m.fontColor || "#181818";
      let fontSize = "20px";

      let left = m.number > 9 || !Number.isInteger(m.number) ? "1.3" : "1.7";
      let top = m.number > 9 || !Number.isInteger(m.number) ? "0.9" : "1.1";

      const icon = L.divIcon({
        html:
          `<img style='position:relative;width:80px;height:80px' src=${iconSrc}>` +
          `<span style="position: absolute;left:${left}em;top:${top}em;font-size:${fontSize};font-weight:${fontWeight};color:${fontColor}" class="my-div-span">${m.number}</span>`,
        className: "numbered-marker-icon",
        iconSize: [30, 30],
        iconAnchor: [15, 30],
      });

      const mk = new L.Marker(position, { draggable, title, icon });
      if (title) mk.bindPopup(title);
      if (tooltip) mk.bindTooltip(tooltip);
      if (onClick) mk.on("click", onClick);
      if (onDragend) mk.on("dragend", onDragend);

      map.addLayer(mk);
      markerBounds.push(mk.getLatLng());
      return mk;
    }
    return null;
  });

  if (markerBounds.length > 0) {
    map.fitBounds(L.latLngBounds(markerBounds));
  }

  return createdMarkers.filter((mk) => mk !== null);
};

const removeMarkers = (map, markers) => {
  markers.forEach((marker) => map.removeLayer(marker));
};

const Map = ({
  center,
  zoomControl,
  location,
  zoom,
  hybrid,
  search,
  markers,
  width,
  height,
  onMapLoad,
  fitbounds
}) => {
  const mapNode = useRef(null);
  const [map, setMap] = useState(null);
  const [currentMarkers, setCurrentMarkers] = useState([]);

  useEffect(() => {
    if (MapmyIndia && MapmyIndia.Map && mapNode.current) {
        const newMap = new MapmyIndia.Map(mapNode.current, {
        center,
        zoomControl,
        location,
        zoom,
        hybrid,
        search,
      });
      setMap(newMap);
      onMapLoad && onMapLoad(newMap);
      const newMarkers = renderMarkers(newMap, markers);
      setCurrentMarkers(newMarkers);
    }
    return () => {
      removeMarkers(map, currentMarkers);
    }
  }, [center, zoomControl, location, zoom, hybrid, search, onMapLoad, markers]);

  return <div ref={mapNode} style={{ width, height }} id="map" className="map" />;
};

Map.defaultProps = {
  center: [18.5314, 73.8446],
  zoomControl: true,
  hybrid: true,
  location: true,
  search: true,
  zoom: 15,
  height: "500px",
  width: "100%",
  markers: [],
};

Map.propTypes = {
  center: PropTypes.array,
  zoomControl: PropTypes.bool,
  location: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  zoom: PropTypes.number,
  hybrid: PropTypes.bool,
  search: PropTypes.bool,
  markers: PropTypes.array,
  onMapLoad: PropTypes.func,
};

export default Map;