import { Box, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import DisplayUserGroup from "./DisplayUserGroup";
import EmailActions from "./EmailActions";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export function collectObjectsWithKey(data: any, key: any, excludeItems: any) {
    if (
        (Array.isArray(data) && data.length === 0) ||
        (typeof data === 'object' && data !== null && Object.keys(data).length === 0)
    ) {
        return [];
    }

    let result: any = [];

    function traverse(obj: any) {
        if (Array.isArray(obj)) {
            obj.forEach(item => traverse(item));
        } else if (typeof obj === 'object' && obj !== null) {
            if (obj.hasOwnProperty(key) && (obj?.[excludeItems?.key] !== excludeItems?.value)) {
                result.push(obj);
            }
            Object.values(obj).forEach(value => traverse(value));
        }
    }

    traverse(data);
    return result;
}

export const columns: any = [
    {
        field: 'name',
        headerName: 'Page',
        align: 'left',
        width: 300,
    },
    {
        field: 'usersOrUserGroups',
        headerName: 'Users',
        align: 'center',
        style: { paddingRight: '0', paddingY: '0', cursor: 'default' },
        renderCell: (value: any, row: { [key: string]: any }) => {
            const users = value || row['usersOrUserGroups'];
            return (
                <>
                    {Array.isArray(users) && users.length > 0 &&
                        users.map((user: any, index: number) => (
                            <TableRow key={index} sx={{
                                padding: '8px',
                                height: '50px',
                                borderBottom: index !== users?.length - 1 ? '1px solid #E0E0E0' : 'none',
                                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                            }}>
                                <TableCell
                                    align="center"
                                    style={{
                                        border: 'none',
                                        padding: '4px 8px',
                                    }}
                                >
                                    {user.type === 'USER_GROUP' ? (
                                        <DisplayUserGroup userGroupData={user} />
                                    ) : (
                                        <Tooltip title={user?.email}>{user?.name}</Tooltip>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                </>
            );
        },
    },
    {
        field: 'frequency',
        headerName: 'Frequency',
        align: 'center',
        style: { paddingLeft: '0', paddingY: '0', cursor: 'default' },
        renderCell: (value: any, row: { [key: string]: any }) => {
            const frequencies = row['usersOrUserGroups']?.map((user: any) => user?.schedule);
            return (
                <>
                    {Array.isArray(frequencies) && frequencies.length > 0 &&
                        frequencies.map((frequency: string, index: number) => (
                            <TableRow key={index} sx={{
                                padding: '8px',
                                height: '50px',
                                borderBottom: index !== frequencies?.length - 1 ? '1px solid #E0E0E0' : 'none',
                                display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center',
                            }}>
                                <TableCell
                                    align="center"
                                    sx={{
                                        border: 'none',
                                        padding: '4px 8px',
                                        display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px'
                                    }}
                                >
                                    {Object.entries(frequency)?.map(([key, value]: any, subIndex: number) => (
                                        <Box
                                            key={subIndex}
                                            display="flex"
                                            alignItems="center"
                                            gap={2}
                                        >
                                            <Typography variant="body2">{frequencyKeyMap?.[key]}</Typography>
                                            {value ? <CheckBoxIcon sx={{ color: (theme) => theme.palette.success.dark }} /> : <CancelIcon color="error" />}
                                        </Box>
                                    ))}

                                </TableCell>
                            </TableRow>
                        ))}
                </>
            );
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        align: 'right',
        renderCell: (value: any, row: { [key: string]: any }) => {
            return (
                <EmailActions data={row} />
            );
        },
    },
];


const frequencyKeyMap: any = {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
};
