
import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import DialogBox from "../../core-components/theme/components/DialogBox/DialogBox";
import { useManageSelections } from "./hook";
import CustomAutocomplete from "../../core-components/theme/components/AutoComplete";

function ConfigureReportsEmailModal({ handleClose, open }: any) {
    const { userSearchInput, selectedPages, users, flatClusters, actions, handleUserSearch, handlePageSelection, handleUserSelection, handleReportFrequency, handleOptionKey } = useManageSelections(handleClose, open);

    return (
        <React.Fragment>
                <DialogBox actions={actions} onClose={handleClose} open={open} TitlefontSize='18px' title='Select Report Type and Users'>
                    <Grid container alignItems='center' rowSpacing={3} columnSpacing={3}>
                        <Grid item xs={4}>
                            <Typography>Select Pages</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomAutocomplete onChange={handlePageSelection} getOptionKey={(option: any) => option?.pageId} disableCloseOnSelect options={flatClusters} readonly={true} multiple limitTags={3} label="Pages" optionLabelKey="label" placeholder="Pages..." />
                        </Grid>
                        {
                            selectedPages?.map((page: any, pageIndex) => {
                                return (
                                    <React.Fragment key={page?.pageId}>{renderSelectedPages(page, handleReportFrequency)}</React.Fragment>
                                );
                            })
                        }
                        <Grid item xs={12} mt={3}>
                            <CustomAutocomplete onChange={handleUserSelection} getOptionKey={handleOptionKey} inputValue={userSearchInput} filterSelectedOptions={false} disableCloseOnSelect onInputChange={handleUserSearch} fullWidth fieldLabel="Select Users to Receive the Report" options={users || []} multiple limitTags={3} label="User" optionLabelKey="name" placeholder="User..." />
                        </Grid>
                    </Grid>
                </DialogBox>
        </React.Fragment>
    )
}

function renderSelectedPages(page: any, handleReportFrequency: any) {
    let schedule = page?.schedule;
    return (
        <>
            <Grid item xs={12}>
                <Divider variant="middle" flexItem />
            </Grid>
            <Grid item xs={4}>
                <Typography>Selected Page</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>{page?.label}</Typography>
            </Grid>

            <Grid item xs={4}>
                <Typography>Choose Report Type</Typography>
            </Grid>
            <Grid item xs={8}>
                <FormGroup row>
                    <FormControlLabel control={<Checkbox checked={schedule?.daily} onChange={(e) => handleReportFrequency(e.target.checked, page, 'daily')} />} label="Daily" />
                    <FormControlLabel control={<Checkbox checked={schedule?.weekly} onChange={(e) => handleReportFrequency(e.target.checked, page, 'weekly')} />} label="Weekly" />
                    <FormControlLabel control={<Checkbox checked={schedule?.monthly} onChange={(e) => handleReportFrequency(e.target.checked, page, 'monthly')} />} label="Monthy" />
                </FormGroup>
            </Grid>
        </>
    )
}

export default memo(ConfigureReportsEmailModal);