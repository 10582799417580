import axiosInstance from '../constants/axiosInstance';
// import { newUserInterface } from '../Interfaces/userInterface';

const getAdministrativeRoles = async (payload: any) => {
  try {
    // console.log('payload', payload);
    const { data } = await axiosInstance().post(`/newUsers/getAdministrativeRoles`, payload);
    return data?.data;
  } catch (error) {
    console.log('error : ', error);
    throw error;
  }
};

const buildWorkspacePermissionTree = async (workspacePermissionTreePayload: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/newUsers/buildWorkspacePermissionTree`,
      workspacePermissionTreePayload
    );
    return data?.data;
  } catch (error) {
    console.log('error : ', error);
    throw error;
  }
};

const saveUser = async (userData: any) => {
  try {
    console.log({ userData });
    const { data } = await axiosInstance().post(`/newUsers/saveUser`, { userData });
    return data;
  } catch (error) {
    console.log('error : ', error);
    throw error;
  }
};

const getUsersList = async () => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/usersList`);
    return data.data;
  } catch (error) {
    console.log('error | getUsersList |', error);
    throw error;
  }
};

const deleteUser = async (payload: any) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/deleteUser`, payload);
    return data.data;
  } catch (error) {
    console.log('error | deleteUser ', error);
    throw error;
  }
};

const getUserDetails = async (userId: string) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/userDetails`, { userId });
    return data.data;
  } catch (err) {
    console.log(`error | getUserDetails :`, err);
    throw err;
  }
};

const getViewAsUserList = async () => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/getViewAsUserList`);
    return data.data;
  } catch (err) {
    console.log(`error | getViewAsUserList :`, err);
    throw err;
  }
};

const createViewAsUserToken = async (viewAsUserId: string) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/createViewAsUserToken`, {
      viewAsUserId,
    });
    return data.data;
  } catch (err) {
    console.log(`error | createViewAsUserToken :`, err);
    throw err;
  }
};

let url = (name: any) => `/newDashboard/dashboardConfigUserListAutocomplete?nameQuery=${name}`
const fetchUsersForEmail = async (name: null | string) => {
  try {
    const { data } = await axiosInstance().get(url(name));
    return data.data;
  } catch (err) {
    console.log(`error | createViewAsUserToken :`, err);
    throw err;
  }
};

export {
  getAdministrativeRoles,
  buildWorkspacePermissionTree,
  saveUser,
  getUsersList,
  deleteUser,
  getUserDetails,
  getViewAsUserList,
  createViewAsUserToken,
  fetchUsersForEmail
};
