import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, Typography } from '@mui/material';
import React from 'react';
import DeleteFilledIcon from '../../core-components/assets/icons/DeleteIconFilled';
import EditIcon from '../../core-components/assets/icons/EditIcon';
import { Neutrals } from '../../core-components/theme/color-palette/colors';
import CustomIconButton from '../../core-components/theme/components/Button/Iconbutton';
import DeleteUserEmailConfig from './DeleteUserEmailConfig';
import EditUserEmailConfig from './EditUserEmailConfig';
import { useEmailActions } from './hook';

export default function EmailActions({data}: any) {
    const { menuAnchorEl, openMenu, closeMenu, selectedAction, handleCloseModal, handleDelete, handleEdit } = useEmailActions(data);
  return (
    <React.Fragment>
      <CustomIconButton onClick={openMenu} icon={MoreVertIcon} />
      <Menu
        anchorEl={menuAnchorEl}
        open={!!menuAnchorEl}
        onClose={closeMenu}
        slotProps={{
          paper: {
            style: {
              boxShadow: '0px 2px 8px 0px #1A335626',
            },
          },
        }}
        MenuListProps={{
          sx: {
            border: `1px solid ${Neutrals[300]}`,
            py: 0,
            backgroundColor: '#ffffff',
            borderRadius: '4px',
          },
        }}
      >
        <MenuItem
          sx={{ height: '36px', paddingLeft: '8px', borderBottom: `1px solid ${Neutrals[300]}` }}
          onClick={handleEdit}
        >
          <EditIcon />
          <Typography ml={2} color={Neutrals[500]}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleDelete} sx={{ height: '36px', paddingLeft: '8px' }}>
          <DeleteFilledIcon />
          <Typography ml={2} color={Neutrals[500]}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>
      {selectedAction === 'edit' && <EditUserEmailConfig userData={data} handleClose={handleCloseModal} open={selectedAction === 'edit'}/>}
      {selectedAction === 'delete' && <DeleteUserEmailConfig userData={data} handleClose={handleCloseModal} open={selectedAction === 'delete'}/>}
    </React.Fragment>
  );
}
