import React from 'react';
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  Paper,
  Typography,
} from '@mui/material';
import { Primary } from '../../color-palette/colors';
import OptionsMenuIcon from '../../../assets/icons/OptionMenu';

interface Column {
  field: string;
  headerName: string | React.ReactNode;
  renderCell?: (value: any, row: { [key: string]: any }) => React.ReactNode;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
  style?: any,
  width?: number
}

interface ReusableTableProps {
  columns: Column[];
  rows: { [key: string]: any }[];
  renderOptionsCell?: (row: { [key: string]: any }) => React.ReactNode;
}

const Table: React.FC<ReusableTableProps> = ({ columns, rows, renderOptionsCell }) => {
  return (
    <MuiTableContainer
      component={Paper}
      elevation={0}
      sx={{ border: `1px solid ${Primary[100]}`, borderRadius: '8px', boxSizing: 'border-box' }}
    >
      <MuiTable>
        <MuiTableHead>
          <MuiTableRow>
            {columns.map(column => (
              <MuiTableCell key={column.field} width={column.width} align={column.align || 'left'}>
                <Typography noWrap variant="highlight">
                  {column.headerName}
                </Typography>
              </MuiTableCell>
            ))}
            {renderOptionsCell && (
              <MuiTableCell key="options" align="right">
                <Typography variant="highlight">
                  <OptionsMenuIcon />
                </Typography>
              </MuiTableCell>
            )}
          </MuiTableRow>
        </MuiTableHead>
        <MuiTableBody>
          {rows?.map((row, rowIndex) => (
            <MuiTableRow
              key={rowIndex}
              sx={{
                ':hover': { backgroundColor: Primary[50] },
                borderBottom: rowIndex !== rows.length - 1 ? '1px solid #E0E0E0' : 'none',
              }}
            >
              {columns.map(column => (
                <MuiTableCell
                  key={column.field}
                  align={column.align || 'left'}
                  sx={{ borderBottom: rowIndex !== rows.length - 1 ? '1px solid #E0E0E0' : 'none', ...column?.style }}
                >
                  {column.renderCell
                    ? column.renderCell(row[column.field], row)
                    : row[column.field]}
                </MuiTableCell>
              ))}
              {renderOptionsCell && (
                <MuiTableCell
                  align="right"
                  key="options"
                  sx={{
                    borderBottom: rowIndex !== rows.length - 1 ? '1px solid #E0E0E0' : 'none',
                  }}
                >
                  {renderOptionsCell(row)}
                </MuiTableCell>
              )}
            </MuiTableRow>
          ))}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};

export default Table;
