import { Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import React from 'react';
import CustomAutocomplete from '../../core-components/theme/components/AutoComplete';
import DialogBox from '../../core-components/theme/components/DialogBox/DialogBox';
import { useEditUserEmailConfig } from './hook';


export default function EditUserEmailConfig({ userData, handleClose, open }: any) {
   const {actions, userScheduleData = [], handleReportFrequency} =  useEditUserEmailConfig(handleClose, userData);

    return (
        <React.Fragment>
            <DialogBox actions={actions} onClose={handleClose} open={open} TitlefontSize='18px' title='Update Report Schedule'>
                <Grid container alignItems='center' rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={4}>
                        <Typography>Select Page</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <CustomAutocomplete preSelectedOptions={[userData]} disabled={true} getOptionKey={(option: any) => option?.dashboardPageId} disableCloseOnSelect options={[userData]} readonly={true} multiple limitTags={1} label="Page" optionLabelKey="name" />
                    </Grid>
                    {
                        userScheduleData?.map((user: any, userIndex: number) => {
                            return (
                                <React.Fragment key={user?.id}>{renderSelectedPages(user, handleReportFrequency)}</React.Fragment>
                            );
                        })
                    }
                </Grid>
            </DialogBox>
        </React.Fragment>
    );
}

function renderSelectedPages(user: any, handleReportFrequency: any) {
    let schedule = user?.schedule;
    return (
        <>
            <Grid item xs={12}>
                <Divider variant="middle" flexItem />
            </Grid>
            <Grid item xs={4}>
                <Typography>Selected User</Typography>
            </Grid>
            <Grid item xs={6}>
                <Typography>{user?.name}</Typography>
            </Grid>

            <Grid item xs={4}>
                <Typography>Choose Report Type</Typography>
            </Grid>
            <Grid item xs={8}>
                <FormGroup row>
                    <FormControlLabel control={<Checkbox checked={schedule?.daily} onChange={(e) => handleReportFrequency(e.target.checked, user, 'daily')} />} label="Daily" />
                    <FormControlLabel control={<Checkbox checked={schedule?.weekly} onChange={(e) => handleReportFrequency(e.target.checked, user, 'weekly')} />} label="Weekly" />
                    <FormControlLabel control={<Checkbox checked={schedule?.monthly} onChange={(e) => handleReportFrequency(e.target.checked, user, 'monthly')} />} label="Monthy" />
                </FormGroup>
            </Grid>
        </>
    )
}
