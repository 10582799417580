import { Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { useCallback, useState } from "react";
import { BlackButton } from "../../styles/global/components/dpButtons";
import ConfigureReportsEmailModal from "./ConfigureModal";
import AddIcon from '@mui/icons-material/Add';

export default function ConfigureReportsEmail() {
    const [openModal, setOpenModal] = useState(false);
    const isMobile = useMediaQuery((theme : any) => theme.breakpoints.down("sm"));

    const handleClose = useCallback(() => {
        setOpenModal(false);
    }, []);

    return (
        <React.Fragment>
            <Tooltip title="Configure Emails">
                <BlackButton
                    onClick={() => setOpenModal(true)}
                >
                    <AddIcon fontSize="small" />
                    {!isMobile && <Typography variant="h6">Configure Report Emails</Typography>}
                </BlackButton>
            </Tooltip>
            {openModal && <ConfigureReportsEmailModal handleClose={handleClose} open={openModal} />}
        </React.Fragment>
    )
}
