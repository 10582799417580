//@ts-nocheck
import { clamp } from 'lodash';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import { useEffect, useRef, useState } from 'react';
import { getAggregationTypeFromId } from '../../../utils/dashboard.utils';
import { deepClone } from '@mui/x-data-grid/utils/utils';

Chart.register(...registerables);

export default function LineGraphComponent(props: any) {
  const {
    data,
    viewEveryNthTick,
    isEditModeEnabled,
    onPointClick,
    incrementValue,
    addRoadValue,
    setDataPointValue,
    onIncrementApplied,
    isDeleteConfirm,
    UpdatedWidgetData,
    onDeletedIdsChange,
    changedPointsOnly,
    onRoadDataChange,
  } = props;

  const chartRef = useRef(null);
  const [editablePoint, setEditablePoint] = useState<any>(null);
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [tempChangedPoint, setTempChangedPoint] = useState(null);

  const handlePointClick = (event: any) => {
    const chart = chartRef.current;
    if (!chart) return;

    const points = chart?.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
    if (points.length) {
      const point = points[0];
      const { datasetIndex, index } = point;
      setSelectedPoint({ datasetIndex, index });

      if (isEditModeEnabled) {
        setEditablePoint({
          datasetIndex,
          index,
          originalValue: data?.datasets[datasetIndex]?.data[index],
        });
      }

      if (onPointClick) onPointClick(index);

      chartRef?.current?.update();
    }
  };

  const handleMouseMove = (event: any) => {
    if (!editablePoint || !chartRef.current || !isEditModeEnabled) return;
    const { index } = selectedPoint;
    const yScale = chartRef?.current?.scales['y'];
    const newYValue = yScale.getValueForPixel(event.nativeEvent.offsetY);

    const updatedData = deepClone(data);
    updatedData.datasets[editablePoint?.datasetIndex].data[editablePoint?.index] = newYValue;
    UpdatedWidgetData(updatedData);

    const _id = data?._ids[index];

    // Temporarily store the latest dragged value
    if (_id) {
      setTempChangedPoint({ _id: _id, value: Number(newYValue) });
    }

    chartRef?.current?.update();
  };

  const handleMouseUp = () => {
    if (tempChangedPoint) {
      // Finalize the change and save it in changedPointsOnly
      changedPointsOnly((prevPoints: any) => {
        const exists = prevPoints.find((point: any) => point.id === tempChangedPoint.id);
        if (!exists) {
          return [...prevPoints, tempChangedPoint];
        }
        return prevPoints.map((point: any) =>
          point.id === tempChangedPoint.id ? tempChangedPoint : point
        );
      });
      setTempChangedPoint(null); // Clear the temporary state
    }

    if (editablePoint) {
      setEditablePoint(null); // Reset editablePoint after mouse up
    }
  };

  // Apply increment to selected data point
  useEffect(() => {
    if (incrementValue !== null && selectedPoint) {
      const { datasetIndex, index } = selectedPoint;
      const updatedData = deepClone(data);
      const updatedValue = (
        parseFloat(updatedData.datasets[datasetIndex].data[index]) + incrementValue
      ).toString();
      updatedData.datasets[datasetIndex].data[index] = updatedValue;
      UpdatedWidgetData(updatedData);
      const _id = data?._ids[index];
      if (_id) {
        changedPointsOnly((prevPoints: any) => [
          ...prevPoints,
          { _id: _id, value: Number(updatedValue) },
        ]);
      }
      if (onIncrementApplied) {
        onIncrementApplied(); // Reset incrementValue in parent
      }
    }
  }, [incrementValue, selectedPoint, onIncrementApplied, data]);

  // Apply "Add Road" increment to the selected point and all subsequent points
  useEffect(() => {
    if (addRoadValue !== null && selectedPoint) {
      const { datasetIndex, index } = selectedPoint;
      const updatedData = deepClone(data);
      const newSensorOffSetData: object[] = []; // Array to hold new roadData objects

      for (let i = index; i < updatedData.datasets[datasetIndex].data.length; i++) {
        const updatedValue = (
          parseFloat(updatedData.datasets[datasetIndex].data[i]) + addRoadValue
        ).toString();

        // Update graph data for visual representation
        updatedData.datasets[datasetIndex].data[i] = updatedValue;
      }
      const operationType = addRoadValue < 0 ? 'DECREMENT' : 'INCREMENT';
      const absoluteValue = Math.abs(addRoadValue);

      const sensorOffSetData = {
        value: absoluteValue, // Increment value
        calculatedAtInMs: updatedData.calculatedAtInMs[index], // Time reference
        operationType: operationType,
      };

      newSensorOffSetData.push(sensorOffSetData); // Add new roadData to the array

      // Send the updated roadData array back via props
      if (onRoadDataChange) {
        onRoadDataChange((prevRoadData: any) => [...prevRoadData, ...newSensorOffSetData]);
      }

      // Reflect updated data on the graph
      UpdatedWidgetData(updatedData);

      if (onIncrementApplied) {
        onIncrementApplied(); // Reset addRoadValue in parent
      }
    }
  }, [addRoadValue, selectedPoint, onIncrementApplied, data, props]);

  // Apply a direct set value to the selected data point
  useEffect(() => {
    if (setDataPointValue !== null && selectedPoint) {
      const { datasetIndex, index } = selectedPoint;
      const updatedData = deepClone(data);
      const updatedValue = setDataPointValue.toString();
      updatedData.datasets[datasetIndex].data[index] = updatedValue;
      UpdatedWidgetData(updatedData);
      const _id = data?._ids[index];
      if (_id) {
        changedPointsOnly((prevPoints: any) => [
          ...prevPoints,
          { _id: _id, value: Number(updatedValue) },
        ]);
      }

      if (onIncrementApplied) {
        onIncrementApplied(); // Reset setDataPointValue in parent
      }
    }
  }, [setDataPointValue, selectedPoint, onIncrementApplied, data]);

  // Handle delete confirm
  useEffect(() => {
    if (isDeleteConfirm && selectedPoint) {
      const { datasetIndex, index } = selectedPoint;
      const updatedData = deepClone(data);

      const _id = data?._ids[index];

      if (_id) {
        onDeletedIdsChange((prevIds: any) => {
          const updatedIds = [...prevIds, _id];
          if (onDeletedIdsChange) {
            onDeletedIdsChange(updatedIds);
          }
          return updatedIds;
        });
      }

      updatedData.datasets[datasetIndex].data.splice(index, 1);
      updatedData.labels.splice(index, 1);
      updatedData.labelsWithYear.splice(index, 1);
      updatedData._ids.splice(index, 1);

      UpdatedWidgetData(updatedData);
      setSelectedPoint(null);
      onPointClick(null);
    }
  }, [isDeleteConfirm, selectedPoint, data, props]);

  return (
    <div
      onClick={handlePointClick}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      style={{ width: '100%', height: '100%' }}
    >
      <Line
        ref={chartRef}
        data={{
          ...deepClone(data),
          datasets: data?.datasets?.map((dataset: any, datasetIndex: any) => ({
            ...deepClone(dataset),
            pointRadius: (context: any) =>
              selectedPoint &&
              selectedPoint.datasetIndex === datasetIndex &&
              selectedPoint.index === context.dataIndex
                ? 8
                : 4,
            pointBackgroundColor: (context: any) =>
              selectedPoint &&
              selectedPoint.datasetIndex === datasetIndex &&
              selectedPoint.index === context.dataIndex
                ? 'black'
                : '#ffffff',
          })),
        }}
        options={{
          plugins: {
            legend: {
              display: false,
              position: 'top',
              labels: {
                boxHeight: 3,
                boxWidth: 30,
                font: {
                  size: context => {
                    const height = context.chart.height;
                    const width = context.chart.width;
                    const size = Math.round(height / 27);
                    return clamp(11, size, 19);
                  },
                },
                color: '#666',
              },
            },
            tooltip: {
              enabled: true,
              callbacks: {
                title: context => {
                  if (context.length > 0) {
                    const index = context[0]?.dataIndex;
                    const data = context[0]?.chart?.data?.labels;
                    const currentTime = data[index] || '';
                    const datasetIndex = context[0]?.datasetIndex;
                    const dataset = context[0]?.chart?.data?.datasets[datasetIndex];
                    const nextTime =
                      index < data?.length - 1 ? data[index + 1] : dataset?.lastTimeLabel;

                    return getAggregationTypeFromId(dataset.id) !== 'Raw'
                      ? `${currentTime} ${nextTime?.length > 0 ? `-` : ''} ${nextTime}`
                      : `${currentTime}`;
                  }
                  return '';
                },
                label: context => {
                  const datasetLabel = context?.dataset?.label || '';
                  const value = context?.parsed?.y;
                  return `Sensor Value: ${value}`;
                },
              },
            },
          },
          scales: {
            x: {
              grid: { display: true },
              ticks: {
                callback: function (val, index) {
                  return index % viewEveryNthTick === 0 ? this.getLabelForValue(val) : '';
                },
                color: '#666',
                font: {
                  size: function (context) {
                    const width = context?.chart?.width;
                    const height = context?.chart?.height;
                    const size = Math.round(height / 27);
                    return clamp(12, size, 16);
                  },
                },
              },
            },
            y: {
              grid: { display: true },
              ticks: {
                color: '#666',
                font: {
                  size: function (context) {
                    const width = context?.chart?.width;
                    const height = context?.chart?.height;
                    const size = Math.round(height / 27);
                    return clamp(12, size, 16);
                  },
                },
              },
            },
          },
          maintainAspectRatio: false,
        }}
        plugins={[]}
      />
    </div>
  );
}
