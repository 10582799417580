import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { InputLabel } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React from "react";

const defaultIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const defaultCheckedIcon = <CheckBoxIcon fontSize="small" />;

interface CustomAutocompleteProps {
    options: any[];
    multiple?: boolean;
    disableCloseOnSelect?: boolean;
    limitTags?: any;
    optionLabelKey?: string;
    label?: string;
    placeholder?: string;
    onChange?: (event: any, value: any) => void;
    renderCustomOption?: (option: any, selected: boolean) => React.ReactNode;
    icon?: React.ReactNode;
    checkedIcon?: React.ReactNode;
    customRenderInput?: (params: any) => React.ReactNode;
    style?: any,
    fieldLabel?: string,
    fullWidth?: any,
    readonly?: boolean,
    onInputChange?: any,
    inputValue?: string,
    loading?: boolean,
    filterSelectedOptions?: boolean,
    getOptionKey?: any,
    disabled?: boolean,
    preSelectedOptions?: any
}

const CustomAutocomplete: React.FC<CustomAutocompleteProps> = ({
    options,
    multiple = false,
    disableCloseOnSelect = false,
    limitTags,
    optionLabelKey = "title",
    label = "",
    placeholder = "",
    onChange,
    onInputChange,
    renderCustomOption,
    icon = defaultIcon,
    checkedIcon = defaultCheckedIcon,
    customRenderInput,
    fieldLabel = '',
    fullWidth,
    style,
    readonly = false,
    inputValue = '',
    loading = false,
    filterSelectedOptions,
    getOptionKey,
    disabled = false,
    preSelectedOptions
}) => {
    // const [input, setInput] = useState('');
    function handleInputChange(value: any) {
        //    setInput(e?.target?.value);
       if(onInputChange) onInputChange(value);
    }
    return (
        <>
            <InputLabel shrink htmlFor="autocomplete-input">
                {fieldLabel}
            </InputLabel>
            <Autocomplete
             
                id="autocomplete-input"
                loading={loading}
                disabled={disabled}
                sx={style}
                filterSelectedOptions={filterSelectedOptions}
                isOptionEqualToValue={(option, value) => {
                    const optionKey = getOptionKey ? getOptionKey(option) : getOptionKey;
                    const valueKey = getOptionKey ? getOptionKey(value) : getOptionKey;
                    return optionKey === valueKey;
                }}
                value={preSelectedOptions}
                // inputValue={inputValue}
                onClose={() => handleInputChange('')}
                fullWidth={fullWidth}
                multiple={multiple}
                disableCloseOnSelect={disableCloseOnSelect}
                options={options}
                limitTags={!!limitTags ? limitTags : false}
                getOptionLabel={(option: any) => option?.[optionLabelKey]}
                onChange={onChange}
                renderOption={(props, option, { selected }) => {
                    const identifier = getOptionKey ? getOptionKey(option) : getOptionKey;
                    return (
                        <li {...props} key={identifier}>
                            {multiple && (
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                            )}
                            {renderCustomOption
                                ? renderCustomOption(option, selected)
                                : option?.[optionLabelKey]}
                        </li>
                    );
                }}
                renderInput={(params) =>
                    customRenderInput ? (
                        customRenderInput(params)
                    ) : (
                        <TextField {...params} onChange={(e: any) => handleInputChange(e.target.value)} InputProps={{
                            ...params.InputProps, readOnly: readonly
                        }}
                            onKeyDown={(e) => {
                                if (readonly) {
                                    e.preventDefault();
                                }
                            }}
                            label={label} placeholder={placeholder} />
                    )
                }
            />
        </>
    );
};

export default CustomAutocomplete;
