import React from 'react';
import Box from '@mui/material/Box';
import LineGraphComponent from './SubComponents/LineGraphComponent';

const styles = {
  container: {
    width: '80vw', // Default width for large screens
    '@media (max-width: 960px)': {
      width: '72vw', // Adjust for medium screens
      height: '50vh', // Adjust for small screens
    },
    '@media (max-width: 380px)': {
      width: '70vw', // Narrower for small screens
    },
    '@media (max-width: 766px)': {
      width: '96vw',
    },

    height: {
      xs: '40vh',
      sm: '40vh',
      md: '47vh',
    },
    paddingTop: '10px',
    paddingBottom: '10px',
    minHeight: '200px',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '8px',
    overflow: 'hidden',
  },
  placeholderText: {
    color: '#b0b0b0',
    fontSize: {
      xs: '12px',
      sm: '14px',
      md: '16px',
    },
  },
};

interface LineGraphProps {
  isEditModeEnabled?: boolean;
  widgetData?: any;
  setInitialGraphData?: (data: any) => void; // Callback for initial data setup
  onPointClick?: (index: number) => void; // Callback for point click
  incrementValue?: number | null;
  addRoadValue?: number | null;
  setDataPointValue?: number | null;
  deletedDataPoints?: Record<number, any>; // Deleted data points info
  onIncrementApplied?: any; // Callback for reset increment
  isDeleteConfirm?: boolean; // Confirmation for delete action
  selectionMode?: boolean; // Selection mode state
  updatedGraphData?: any;
  onDeletedIdsChange?: any;
  changedPointsOnly?: any;
  onRoadDataChange?: any;
}

const LineGraph: React.FC<LineGraphProps> = ({
  isEditModeEnabled,
  widgetData,
  onPointClick,
  incrementValue,
  addRoadValue,
  setDataPointValue,
  deletedDataPoints,
  onIncrementApplied,
  isDeleteConfirm,
  selectionMode,
  updatedGraphData,
  onDeletedIdsChange,
  changedPointsOnly,
  onRoadDataChange,
}) => {
  if (widgetData == null) {
    return (
      <Box sx={styles.container}>
        <Box sx={styles.placeholderText}>Graph will be displayed here</Box>
      </Box>
    );
  }
  if (
    widgetData?.datasets?.length === 0 ||
    widgetData?.labels?.length === 0 ||
    widgetData?.labelsWithYear?.length === 0
  ) {
    return (
      <Box sx={styles.container}>
        <Box sx={styles.placeholderText}>Sensor has no value in the given timeFrame</Box>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <LineGraphComponent
        data={widgetData}
        enableZoom={false}
        viewEveryNthTick={1}
        ticksColor={'#ffffff'}
        resetZoom={false}
        isEditModeEnabled={isEditModeEnabled}
        onPointClick={onPointClick}
        incrementValue={incrementValue}
        addRoadValue={addRoadValue}
        setDataPointValue={setDataPointValue}
        deletedDataPoints={deletedDataPoints}
        onIncrementApplied={onIncrementApplied}
        isDeleteConfirm={isDeleteConfirm}
        selectionMode={selectionMode}
        UpdatedWidgetData={updatedGraphData}
        onDeletedIdsChange={onDeletedIdsChange}
        changedPointsOnly={changedPointsOnly}
        onRoadDataChange={onRoadDataChange}
      />
    </Box>
  );
};

export default LineGraph;
