import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface ILoader {
  color?: string,
  height?: string
}
const Loader = ({ color='#36A2C7', height="80vh" }: ILoader) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height={height}>
      <CircularProgress style={{ color }} />
    </Box>
  );
};

export default Loader;