function filterGranularityOptionsBasedOnTimeRange(timeRangeInMins: number) {
  let allowedGranularity: string[] = [];
  const ONE_DAY = 1440;
  if (timeRangeInMins < 3 * ONE_DAY) {
    allowedGranularity = ['minutes', 'hours', 'days'];
  } else if (timeRangeInMins > 3 * ONE_DAY && timeRangeInMins <= 7 * ONE_DAY) {
    allowedGranularity = ['hours', 'days'];
  } else if (timeRangeInMins > 7 * ONE_DAY && timeRangeInMins <= 60 * ONE_DAY) {
    allowedGranularity = ['days', 'weeks'];
  } else if (timeRangeInMins > 60 * ONE_DAY) {
    allowedGranularity = ['weeks', 'months'];
  }
  return allowedGranularity;
}

const add530Hours = (
  startDate: Date | string | null | undefined,
  endDate?: Date | string | null | undefined
) => {
  if (!startDate || !endDate) {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  const offsetMilliseconds = 1000 * 60 * (5 * 60 + 30); // 5 hours 30 minutes in milliseconds
  const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
  const end = typeof endDate === 'string' ? new Date(endDate) : endDate;

  return {
    startDate: new Date(start.getTime() + offsetMilliseconds).toISOString(),
    endDate: new Date(end.getTime() + offsetMilliseconds).toISOString(),
  };
};

const getAggregationTypeFromId = (id: string) => {
  if (id.includes('max')) return 'Maximum';
  else if (id.includes('min')) return 'Minimum';
  else if (id.includes('tws') || id.includes('hoursRun')) return 'Time weighted sum';
  else if (id.includes('cum')) return 'Cumulative';
  else if (id.includes('avg')) return 'Average';
  else return 'Raw';
};

const getLayoutFromPageData = (pageData: any, removeSizeOptions = true) => {
  //nalin_sort_function
  let layout: any[] = [];

  if (pageData.data.length > 0) {
    pageData.data.forEach((data: any, clusterIndex: number) => {
      let widgets = data.widgets;
      widgets.forEach((widget: any, widgetIndex: number) => {
        let defaultGridProps = widget.gridProps?.xl ?? { x: 0, y: widgetIndex * 2, w: 1, h: 1 };
        let layoutItem = {};
        if (removeSizeOptions)
          layoutItem = {
            ...defaultGridProps,
            resizeHandles: [],
            i: `${clusterIndex}-${widgetIndex}`,
          };
        else layoutItem = { ...defaultGridProps, i: `${clusterIndex}-${widgetIndex}` };
        layout.push(layoutItem);
      });
    });
  }
  layout.sort((node1: any, node2: any) => {
    return node1.y > node2.y ? 1 : -1;
  });
  console.log('gridLayout : ', layout);
  return layout;
};

const convertFlowDataToCSV = (flowData: any) => {
  const csvData = [];
  // Add headers
  csvData.push(['Source', 'Target', 'Value']);

  // Iterate through the links to build the CSV rows
  flowData.links.forEach((link: any) => {
    const sourceName = flowData.nodes[link.source]?.name;
    const targetName = flowData.nodes[link.target]?.name;
    csvData.push([sourceName, targetName, link.value]);
  });

  // Create a CSV string by joining the rows with line breaks
  return csvData.map(row => row.join(',')).join('\n');
};

const downloadCSV = (csvString: string, filename = 'WidgetData.csv') => {
  // Create a Blob containing the CSV data
  const blob = new Blob([csvString], { type: 'text/csv' });

  // Create a temporary URL to the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary anchor element to trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = filename; // Set the desired filename

  // Trigger a click event on the anchor element to initiate the download
  a.click();

  // Clean up resources
  window.URL.revokeObjectURL(url);
};

export {
  filterGranularityOptionsBasedOnTimeRange,
  add530Hours,
  getAggregationTypeFromId,
  getLayoutFromPageData,
  convertFlowDataToCSV,
  downloadCSV,
};
