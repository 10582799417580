
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ConfigureReportsEmail from ".";
import Table from "../../core-components/theme/components/Table/Table";
import Loader from "../../globalComponents/loader/DPLoader";
import { columns } from "./helper";
import { useFetchEmailConfig } from "./hook";

export default function EmailListing() {

    return (
        <React.Fragment>
            <Box m={4}>
                <Stack mb={4} direction="row" justifyContent={'space-between'} alignItems='center'>
                    <Typography sx={{
                        typography: {
                            sm: "h6",
                            md: "h2",
                        },
                    }} color={'secondary.dark'}>
                        Configure Recurring Reports
                    </Typography>
                    <ConfigureReportsEmail />
                </Stack>
                <ListingContainer />
            </Box>
        </React.Fragment>
    )
}

function ListingContainer() {
    const { data, isLoading } = useFetchEmailConfig();
    const rowsData = data?.data?.listOfScheduledEmails || [];

    if (isLoading) return <Loader height="30vh" />

    return <Table rows={rowsData} columns={columns || []} />
}

