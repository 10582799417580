import { useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import cloneDeep from 'lodash/cloneDeep';
import { getDashboardClustersForClient } from '../../services/dashboardPage';
import { fetchUsersForEmail } from '../../services/newUsers';
import { collectObjectsWithKey } from './helper';
import axiosInstance from '../../constants/axiosInstance';
import { toast } from 'react-toastify';
import useDebounce from '../../utilities/customHooks/useDebounce';

const postData = async (payload: any) => {
    const response = await axiosInstance().post('/newDashboard/createDashboardScheduledEmail', payload);
    return response.data;
};

function hasTrueValue(array: any) {
    return array?.every((item: any) => {
        const schedule = item?.schedule;
        return schedule && Object?.values(schedule)?.some(value => value === true);
    });
}

export function useManageSelections(handleClose: any, isOpen: boolean) {
    const [userSearchInput, setUserSearchInput] = useState<any>('');
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedPages, setSelectedPages] = useState([]);
    const queryClient: any = useQueryClient();

    const debouncedSearch = useDebounce(handleUserSearch);

    const { data: usersListData, isLoading: areUsersLoading } = useQuery({
        queryKey: ['usersList', userSearchInput],
        queryFn: () => fetchUsersForEmail(userSearchInput),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });

    const { data: clusters, isLoading: arePagesLoading } = useQuery({
        queryKey: ['clusterList'],
        queryFn: getDashboardClustersForClient,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });

    const { mutate } = useMutation({
        mutationFn: postData,
        onSuccess: data => {
            toast.success(data?.message || `Emails Configured`);
            queryClient.invalidateQueries({ queryKey: ['usersEmailConfig'] });
            if (handleClose) handleClose();
        },
        onError: error => {
            toast.error(error?.message || `Failed to Configure Emails`);
        },
    });

    const flatClusters = useMemo(() => {
        const clustersWithSchedule = collectObjectsWithKey(clusters, 'pageId', { key: 'sharedForViewOnly', value: true }).map((item: any) => ({
            ...item,
            schedule: {
                daily: false,
                weekly: false,
                monthly: false,
            },
        }));
        return clustersWithSchedule;
    }, [clusters]);

    const users = useMemo(() => usersListData, [usersListData]);

    function handleUserSearch(value: any) {
        setUserSearchInput(value);
    }

    function handleOptionKey(option: any) {
        return option?.id;
    }

    function handlePageSelection(event: any, value: any) {
        setSelectedPages(cloneDeep(value));
    }

    function handleUserSelection(event: any, value: any) {
        setSelectedUsers(cloneDeep(value));
    }

    function handleReportFrequency(value: any, selectedPage: any, key: string) {
        setSelectedPages((prevPages: any) => {
            return prevPages?.map((page: any) =>
                page?.pageId === selectedPage?.pageId
                    ? {
                        ...page, schedule: {
                            ...page?.schedule,
                            [key]: value,
                        },
                    }
                    : page
            );
        });
    }

    function handleSubmit() {
        const payload: any = {
            emailConfiguration: selectedPages?.map(({ pageId, schedule, folderId }) => ({ pageId, schedule, clusterId: folderId })),
            userIdsOrUserGroups: selectedUsers?.map(({ id, type }) => ({ id, type })),
        };
        mutate(payload);
    }

    const actions = useMemo(
        () => [
            {
                text: 'Cancel',
                handler: handleClose,
                color: 'secondary',
                textColor: '#8B8B8B4D',
                variant: 'outlined',
            },
            {
                text: 'Submit',
                type: 'submit',
                disabled: !(!!selectedUsers?.length && !!selectedPages?.length && hasTrueValue(selectedPages)),
                handler: handleSubmit,
            },
        ],
        [selectedUsers, selectedPages, flatClusters]
    );

    return {
        userSearchInput,
        setUserSearchInput,
        selectedUsers,
        setSelectedUsers,
        selectedPages,
        setSelectedPages,
        users,
        areUsersLoading,
        flatClusters,
        arePagesLoading,
        actions,
        handleUserSearch: debouncedSearch,
        handlePageSelection,
        handleUserSelection,
        handleReportFrequency,
        handleOptionKey,
    };
}

const fetchEmailConfig = async () => {
    const response = await axiosInstance().get('/newDashboard/getListOfDashboardScheduledEmails');
    return response.data;
};

export function useFetchEmailConfig() {
    const { data, isLoading } = useQuery({
        queryKey: ['usersEmailConfig'],
        queryFn: fetchEmailConfig,
    });
    return {
        data,
        isLoading
    }
}

const postEditedData = async (payload: any) => {
    const response = await axiosInstance().post('/newDashboard/editDashboardScheduleEmail', payload);
    return response.data;
};

export function useEditUserEmailConfig(handleClose: any, userData: any) {
    const [userScheduleData, setUserScheduleData] = useState(() => userData?.usersOrUserGroups);
    const queryClient: any = useQueryClient();

    const { mutate } = useMutation({
        mutationFn: postEditedData,
        onSuccess: data => {
            toast.success(data?.message || `Emails Configured`);
            queryClient.invalidateQueries({ queryKey: ['usersEmailConfig'] });
            if (handleClose) handleClose();
        },
        onError: error => {
            toast.error(error?.message || `Failed to Configure Emails`);
        },
    });

    const actions = (handleClose: any, handleSubmit: any) =>
        useMemo(
            () => [
                {
                    text: 'Cancel',
                    handler: handleClose,
                    color: 'secondary',
                    textColor: '#8B8B8B4D',
                    variant: 'outlined',
                },
                {
                    text: 'Submit',
                    type: 'submit',
                    handler: handleSubmit,
                },
            ],
            [handleClose, handleSubmit]
        );

    function handleReportFrequency(value: any, selectedUser: any, key: string) {
        setUserScheduleData((prevUsers: any) => {
            return prevUsers?.map((user: any) =>
                user?.id === selectedUser?.id
                    ? {
                        ...user, schedule: {
                            ...user?.schedule,
                            [key]: value,
                        },
                    }
                    : user
            );
        });
    }

    function handleSubmit() {
        const payload = {
            dashboardPageId: userData?.dashboardPageId,
            userIdsOrUserGroups: userScheduleData?.map(({ id, schedule }: any) => ({ id, schedule })),
        };
        mutate(payload);
    }

    return {
        actions: actions(handleClose, handleSubmit),
        handleReportFrequency,
        userScheduleData
    }
}

const deleteUserGroup = async (payload: any) => {
    const response = await axiosInstance().post('/newDashboard/deleteDashboardScheduleEmail', payload);
    return response.data;
};

export function useDeleteUserEmailConfig(handleClose: any, userData: any) {
    const queryClient: any = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: deleteUserGroup,
        onSuccess: (data: any) => {
            toast.success(data?.message || `User Group Deleted`);
            queryClient.invalidateQueries({ queryKey: ['usersEmailConfig'] });
            handleClose();
        },
        onError: error => {
            toast.error(error?.message || `Failed to Delete User Group`);
        },
    });

    const handleEmailGroupDelete = useCallback(() => {
        mutate({ dashboardPageId: userData?.dashboardPageId });
    }, [userData?.dashboardPageId, mutate]);

    const actions = (handleClose: any, handleDelete: any) =>
        useMemo(
            () => [
                {
                    text: 'Cancel',
                    color: '#E0E0E0',
                    textColor: '#000',
                    handler: handleClose,
                },
                {
                    text: 'Delete',
                    color: '#FF0000',
                    textColor: '#FFF',
                    handler: handleDelete,
                },
            ],
            [handleClose, handleDelete]
        );

    return {
        actions: actions(handleClose, handleEmailGroupDelete)
    }

}

export function useEmailActions(data: any) {
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedAction, setSelectedAction] = useState<null | string>(null);

    const openMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    }, []);

    const closeMenu = useCallback(() => {
        setMenuAnchorEl(null);
    }, []);

    const handleCloseModal = useCallback(() => {
        setSelectedAction(null);
    }, []);

    const handleDelete = useCallback(() => {
        setSelectedAction('delete');
        closeMenu();
    }, [closeMenu]);

    const handleEdit = useCallback(() => {
        setSelectedAction('edit');
        closeMenu();
    }, [closeMenu]);

    return {
        menuAnchorEl,
        openMenu,
        closeMenu,
        selectedAction,
        handleCloseModal,
        handleDelete,
        handleEdit,
    };
}
