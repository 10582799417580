import { Chip } from "@mui/material";
import React, { useState } from "react";
import DialogBox from "../../core-components/theme/components/DialogBox/DialogBox";
import Table from "../../core-components/theme/components/Table/Table";

export default function DisplayUserGroup({ userGroupData }: any) {
    const [openModal, setOpenModal] = useState(false);

    const columns : any = [
        {
            field: 'name',
            headerName: 'Name',
            align: 'center',
        },
        {
            field: 'email',
            headerName: 'Email',
            align: 'center',
        },
    ]

    const actions = [
        {
            text: 'Cancel',
            handler: () => setOpenModal(false),
            color: 'secondary',
            textColor: '#8B8B8B4D',
            variant: 'outlined',
        },
    ]
    return (
        <React.Fragment>
            <Chip
                onClick={() => setOpenModal(true)}
                label={userGroupData?.name}
                sx={{
                    color: '#183650',
                    border: '1px solid #BBD5EB',
                    background: '#sdfvhu',
                }}
            />

            {openModal &&
                <DialogBox actions={actions} onClose={() => setOpenModal(false)} open={openModal} TitlefontSize='18px' title={userGroupData?.name}>
                 <Table rows={userGroupData?.users || []} columns={columns || []}/>
                </DialogBox>
            }

        </React.Fragment>
    )
}
